/**
 *
 * @param file
 * @returns {Promise<*>}
 */
import {postDocumentImg} from "@/service/upData_img";

export async function update(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postDocumentImg(formData);
}

