import axios from "axios";
const headers = 'https://yun-new-admin-api.yunicu.com'  //正式环境
// const headers='http://admin-dev-api.yunicu.com'  //测试环境

// const headers='http://192.168.31.160:55555' //韩笑
// const headers='http://192.168.31.194:55555'

export function post(url,params) {
    return axios.post(headers+url,params
        ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function get(url,params) {
    return axios.get(headers+url, {
        params
    }).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}



