<template>
<!--  意见反馈-->
<div class="container feedBack">
  <div>
    <van-form @submit="onSubmit">
      <van-field
          v-model="formObject.title"
          name="标题"
          label="标题"
          rows="2"
          type="textarea"
          autosize
          :required="true"
          placeholder="请填写"
          :rules="formRyles.title"
      />
      <van-field
          v-model="formObject.describe"
          rows="3"
          autosize
          label="详细描述"
          type="textarea"
          maxlength="250"
          :required="true"
          placeholder="请详细描述您遇到的问题，并上传相关页面的截图，使得我们为您提供更好的帮助"
          :rules="formRyles.describe"
          show-word-limit
      />
      <div class="upImg">
        <span>上传图片</span>
        <span>{{ formObject.imgList.length }}/3</span>
      </div>
      <van-uploader
          v-model="fileList"
          style="padding: 10px"
          :after-read="uploadImg"
          :before-delete="delImg"
          multiple
          :max-count="3" />
      <van-button
          :class="[aType === 'android'||aType ==='ios'?'submitOk':'submitOkPc']"
          round block type="info" native-type="submit">提交</van-button>
    </van-form>
  </div>

</div>
</template>

<script>
import {update} from '@/utils/update'
import {postMyFeedBack} from "@/service/helpCenter";

import { Toast } from 'vant';
export default {
  name: "feedBack",
  data(){
    return{
      formObject:{
        describe:'',
        title:'',
        imgList:[],
      },
      // **登录表单校验规则**
      formRyles: {
        // 手机号校验规则
        title: [
          { required: true, message: '请输入标题' },
        ],
        // 验证码校验规则
        describe: [
          { required: true, message: '请输入详细描述' },
        ]
      },
      fileList:[],
      aType:'',

    }
  },
  created() {
    //  获取本地存储的系统
    this.aType = localStorage.getItem('helpCenterType')

  },
  methods:{
    async onSubmit() {
      let form = this.formObject
      let data = {
        title: form.title,  //标题
        content: form.describe, //详细描述
        images: form.imgList,        //图片,可空
        memberId: this.$route.query.memberId, //用户id
        channel: "投诉渠道"    //可空
      }
      const response = await postMyFeedBack(data)
      if (response.code === 0) {
        // this.infoList = response.data
        Toast.success('反馈成功~');
        this.$router.back(0)
      } else {
        this.$message.warning(response.message)
      }
    },
    upload(){
      // let inputDOM = this.$refs.addImg.files[0];
    },
    async uploadImg(file) {
      // console.log(file.file);
      const image = await update(file.file)
      if (image.code === 0) {
        this.formObject.imgList.push(image.data.url)
      }
    },
    delImg(file, detail){
      this.formObject.imgList.splice(detail.index,1)
      this.fileList.splice(detail.index,1)
    }
  },
}
</script>

<style lang="scss" scoped>
.feedBack{
  //padding: 10px;

  .upImg{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
}
.submitOk{
  width: 90%;
  margin-left: 5%;
  position: fixed;
  bottom: 0;
}
.submitOkPc{
  width: 90%;
  margin-left: 5%;
  position: absolute;
  bottom: 0;
}

</style>
